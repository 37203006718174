import React, { useEffect, useState, useMemo } from 'react';
import { Box, Heading, Card, CardBody, CardHeader } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import FilterForm from '../components/FilterForm';
import InsuranceTable from '../components/InsuranceTable';
import Pagination from '../components/Pagination';
import { useTableColumns } from '../hooks/useTableColumns';
import { formatToLocalISOString } from '../utils/dateUtils';
import api from '../api';
import { logout } from '../auth';

const InsuranceOrders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({
    name: '',
    noKontrak: '',
    noKtp: '',
    trxId: '',
    startDate: null,
    endDate: null,
  });
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);

  const columns = useTableColumns();
  const data = useMemo(() => orders, [orders]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const params = {
        skip: currentPage * pageSize,
        limit: pageSize,
        name: filters.name || undefined,
        no_kontrak: filters.noKontrak || undefined,
        no_ktp: filters.noKtp || undefined,
        trxid: filters.trxId || undefined,
        start_date: filters.startDate ? formatToLocalISOString(filters.startDate) : undefined,
        end_date: filters.endDate ? formatToLocalISOString(filters.endDate) : undefined,
      };
      const response = await api.get('/insurance/', { params });

      setOrders(response.data.orders);
      setPageCount(Math.ceil(response.data.total / pageSize));
    } catch (error) {
      console.error('Error fetching orders', error);
      logout();
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [currentPage, pageSize, filters]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setCanNextPage(newPage < pageCount - 1);
    setCanPreviousPage(newPage > 0);
  };

  return (
    <Box>
      <Navbar />
      <Box p={5}>
        <Card>
          <CardHeader>
            <Heading as="h1" fontSize="lg">Filters</Heading>
          </CardHeader>
          <CardBody>
            <FilterForm filters={filters} setFilters={setFilters} />
          </CardBody>
        </Card>

        <Card mt={5}>
          <CardHeader>
            <Heading as="h1" fontSize="lg">Insurance Data</Heading>
          </CardHeader>
          <CardBody>
            <InsuranceTable
              columns={columns}
              data={data}
              loading={loading}
            />
            <Pagination
              currentPage={currentPage}
              pageCount={pageCount}
              pageSize={pageSize}
              setPageSize={setPageSize}
              onPageChange={handlePageChange}
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
            />
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
};

export default InsuranceOrders;
